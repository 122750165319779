/* eslint-disable react/jsx-pascal-case */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Accordion } from "react-bootstrap";
import GeneralCoverHeader from "../GeneralCoverHeader";
import Footer from "../Footer";
import "./style.css";

class FAQ extends Component {
  state = {
    faq: true,
    investors: false,
    funders: false,
  };

  toggleFaqs(faq, investors, funders) {
    this.setState({
      faq,
      investors,
      funders,
    });
  }

  render() {
    const { faq, investors, funders } = this.state;
    const { userRoute } = this.props;

    return (
      <React.Fragment>
        <GeneralCoverHeader header="FAQ" />
        <section className="faqSection">
          <div className="faqDiv mainContainer">
            <div className="faqTextDiv">
              <p className="faqText sourceBold">
                Investing in crowdfunding is risky and investors may lose their
                entire investment. We strongly encourage investors to perform
                your own research and due diligence before making an investment
                decision.
              </p>
            </div>
            <div className="faqNavbarDiv">
              <a
                href="#/"
                className={`faqNavlink hoverDelay sourceRegular ${
                  faq && "faqNavlinkActive"
                }`}
                onClick={() => this.toggleFaqs(true, false, false)}
              >
                FAQ
              </a>
              <a
                href="#/"
                className={`faqNavlink hoverDelay sourceRegular ${
                  investors && "faqNavlinkActive"
                }`}
                onClick={() => this.toggleFaqs(false, true, false)}
              >
                Investerrs
              </a>
              <a
                href="#/"
                className={`faqNavlink hoverDelay sourceRegular ${
                  funders && "faqNavlinkActive"
                }`}
                onClick={() => this.toggleFaqs(false, false, true)}
              >
                Funderrs
              </a>
            </div>
            <div className="faqMainDiv">
              {faq && (
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What is regulation CF?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      When Regulation Crowdfunding (Reg CF) became law in 2016,
                      it opened up a new type of asset with potential for
                      remarkable rewards: By investing as a “crowd,” even the
                      most ordinary investors have plenty of options to explore.
                      Previously, such opportunities were only available to
                      wealthy, accredited individuals—or they encompass
                      burdensome legal and administrative things that
                      complicated the investment process too much for averages
                      folks. However, due to “Title III” passage of the JUBS
                      Act, everyone has an even opportunity to benefit from
                      activities like crowdinvesting. Joining this exclusive
                      "club" affords access to asset classes normally reserved
                      only for people who wield large sums of money – giving
                      minority and lower financial bracketed entrepreneurs
                      newfound advantage when building their businesses.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What's an accredited investor?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {/* Per SEC - any individual with an individual income
                      exceeding $200,000 in each of the two most recent years or
                      joint income with a spouse or spousal equivalent exceeding
                      $300,000 for those years, and a reasonable expectation of
                      the same income level in the current year; or an
                      individual with a net worth or joint net worth with a
                      spouse or spousal equivalent of at least $1 million, not
                      including the value of his or her primary residence. */}
                      <p>
                        a) Any natural person who had an individual income in
                        excess of $200,000 in each of the two most recent years
                        or joint income with that person's spouse or{" "}
                        <a
                          href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=8db0b0bded928f27dce3c6467f1c6945&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                          className="generalLink"
                          title="Spousal Equivalent"
                          target="_blank"
                          rel="noreferrer"
                        >
                          spousal equivalent
                        </a>{" "}
                        in excess of $300,000 in each of those years and has a
                        reasonable expectation of reaching the same income level
                        in the current year; The term spousal equivalent shall
                        mean a cohabitant occupying a relationship generally
                        equivalent to that of a spouse; or
                      </p>
                      <p>
                        b) a natural person whose today net worth (i.e., excess
                        of total assets over total liabilities) exceeds
                        $1,000,000, except that: a) the person's primary
                        residence shall not be included as an asset; b)
                        indebtedness that is secured by the person's primary
                        residence, up to the estimated fair market value of the
                        primary residence at the time of the sale of securities,
                        shall not be included as a liability (except that if the{" "}
                        <a
                          href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=5c8ffa363e62cc98ec44e4cc891b775d&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                          className="generalLink"
                          title="Spousal Equivalent"
                          target="_blank"
                          rel="noreferrer"
                        >
                          amount
                        </a>{" "}
                        of such indebtedness outstanding at the time of sale of
                        securities exceeds the{" "}
                        <a
                          href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=5c8ffa363e62cc98ec44e4cc891b775d&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                          className="generalLink"
                          title="Spousal Equivalent"
                          target="_blank"
                          rel="noreferrer"
                        >
                          amount
                        </a>{" "}
                        outstanding 60 days before such time, other than as a
                        result of the acquisition of the primary residence, the{" "}
                        <a
                          href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=5c8ffa363e62cc98ec44e4cc891b775d&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                          className="generalLink"
                          title="Spousal Equivalent"
                          target="_blank"
                          rel="noreferrer"
                        >
                          amount
                        </a>{" "}
                        of such excess shall be included as a liability); and c)
                        indebtedness that is secured by the person's primary
                        residence in excess of the estimated fair market value
                        of the primary residence at the time of the sale of
                        securities shall be included as a liability; or
                      </p>
                      <p>
                        c) Any bank as defined in section 3(a)(2) of the
                        Securities Act of 1933 (the “Act”), or any savings and
                        loan association or other institution as defined in
                        section 3(a)(5)(A) of the Act whether acting in its
                        individual or fiduciary capacity; any broker or dealer
                        registered pursuant to section 15 of the Securities
                        Exchange Act of 1934 (the “Exchange Act”); any
                        investment adviser registered pursuant to section 203 of
                        the Investment Advisers Act of 1940 (the “Advisers Act”)
                        or registered pursuant to the laws of a state; any
                        investment adviser relying on the exemption from
                        registering with the SEC under section 203(l) or (m) of
                        the Advisers Act; any insurance company as defined in
                        section 2(a)(13) of the Act; any investment company
                        registered under the Investment Company Act of 1940 (the
                        “40 Act”) or a business development company as defined
                        in section 2(a)(48) of the 40 Act; any Small Business
                        Investment Company licensed by the U.S. Small Business
                        Administration under section 301(c) or (d) of the Small
                        Business Investment Act of 1958; any Rural Business
                        Investment Company as defined in section 384A of the
                        Consolidated Farm and Rural Development Act; any plan
                        established and maintained by a state, its political
                        subdivisions, or any agency or instrumentality of a
                        state or its political subdivisions, for the benefit of
                        its employees, if such plan has total assets in excess
                        of $5,000,000; any employee benefit plan within the
                        meaning of the Employee Retirement Income Security Act
                        of 1974 if the investment decision is made by a plan
                        fiduciary, as defined in section 3(21) of such act,
                        which is either a bank, savings and loan association,
                        insurance company, or registered investment adviser, or
                        if the employee benefit plan has total assets in excess
                        of $5,000,000 or, if a self-directed plan, with
                        investment decisions made solely by persons that are
                        accredited investors; or
                      </p>
                      <p>
                        d) Any organization described in section 501(c)(3) of
                        the Internal Revenue Code, corporation, Massachusetts or
                        similar business trust, partnership, or limited
                        liability company, not formed for the specific purpose
                        of acquiring the securities offered, with total assets
                        in excess of $5,000,000; or
                      </p>
                      <p>
                        e) Any{" "}
                        <a
                          href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=839a969a537e67e83f39146e3537ec5e&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                          className="generalLink"
                          title="Spousal Equivalent"
                          target="_blank"
                          rel="noreferrer"
                        >
                          director
                        </a>
                        ,{" "}
                        <a
                          href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=8f3c18f254557c20e5e39dccb3c05188&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                          className="generalLink"
                          title="Spousal Equivalent"
                          target="_blank"
                          rel="noreferrer"
                        >
                          executive officer
                        </a>{" "}
                        , or general partner of the{" "}
                        <a
                          href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=8ef38ef231dcd4f032b6403f8f0d8971&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                          className="generalLink"
                          title="Spousal Equivalent"
                          target="_blank"
                          rel="noreferrer"
                        >
                          issuer
                        </a>{" "}
                        of the securities being offered or sold, or any{" "}
                        <a
                          href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=839a969a537e67e83f39146e3537ec5e&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                          className="generalLink"
                          title="Spousal Equivalent"
                          target="_blank"
                          rel="noreferrer"
                        >
                          director
                        </a>
                        ,{" "}
                        <a
                          href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=8f3c18f254557c20e5e39dccb3c05188&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                          className="generalLink"
                          title="Spousal Equivalent"
                          target="_blank"
                          rel="noreferrer"
                        >
                          executive officer
                        </a>{" "}
                        , or general partner of a general partner of that{" "}
                        <a
                          href="https://www.law.cornell.edu/definitions/index.php?width=840&height=800&iframe=true&def_id=8ef38ef231dcd4f032b6403f8f0d8971&term_occur=999&term_src=Title:17:Chapter:II:Part:230:Subjgrp:47:230.501"
                          className="generalLink"
                          title="Spousal Equivalent"
                          target="_blank"
                          rel="noreferrer"
                        >
                          issuer
                        </a>
                        ; or
                      </p>
                      <p>
                        f) Any entity, of a type not listed above, not formed
                        for the specific purpose of acquiring the securities
                        offered, owning investments in excess of $5,000,000 as
                        defined in rule 2a51-1(b) under the 40 Act; or
                      </p>
                      <p>
                        g) Any “family office,” as defined in rule
                        202(a)(11)(G)-1 under the Advisers Act. (i) with assets
                        under management in excess of $5,000,000, (ii) That is
                        not formed for the specific purpose of acquiring the
                        securities offered, and (iii) whose prospective
                        investment is directed by a person who has such
                        knowledge and experience in financial and business
                        matters that such family office is capable of evaluating
                        the merits and risks of the prospective investment; and
                        any “family client,” as defined in rule 202(a)(11)(G)-1
                        under the Advisers Act, of a family office meeting the
                        requirements in paragraph (a)(12) of this section and
                        whose prospective investment in the issuer is directed
                        by such family office pursuant to paragraph
                        (a)(12)(iii).
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What are securities?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      A security is an investment in a business.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What's an institutional investor?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        a) a bank, savings and loan institution, insurance
                        company or registered investment company; or
                      </p>
                      <p>
                        b) an investment adviser registered either with the SEC
                        under Section 203 of the Advisers Act or with a state
                        securities commission; or
                      </p>
                      <p>
                        c) any person (whether a natural person, a corporation,
                        partnership, trust, or otherwise) with total assets of
                        at least $50 million.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
              {investors && (
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What's an Investerr?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      An investerr is any person or other entity (such as a firm
                      or mutual fund) on the Vesterr Crowdfunding Platform who
                      commits capital with the expectation of receiving
                      financial returns
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What's the minimum to invest?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      The minimum to invest varies per deal but the minimum to
                      invest is $5,000
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        How can I fund my investment?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      There are several options available to fund your
                      investment: ACH transfer, credit /debit cards up to
                      $5,000, and wire.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What's the max I can invest?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      If you're a non-accredited investor - most investors are -
                      the amount you can invest under Regulation Crowdfunding
                      during any 12 month period depends on your annual income
                      level and net worth. As a non-accredited investor, you can
                      invest the greater of 
                      <ul>
                        <li>$2,500; or</li>
                        <li>
                          If your annual income or net worth is less than
                          $124,000, you can invest <u>5%</u> of the greater of
                          your annual income or net worth; or
                        </li>
                        <li>
                          If both your income and net worth are equal to or
                          more than $124,000, you can invest 10% of the greater
                          of your annual income or net worth, not to exceed an
                          amount of $124,000.
                        </li>
                        <li>
                          Remember this limit applies across all Reg CF deals,
                          so if you invest in Reg CF deals on other platforms,
                          you will need to disclose this too (and keep the
                          disclosure updated) so we properly track your limit.
                          You are responsible for maintaining the veracity of
                          the relevant figures.
                        </li>
                      </ul>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What's the credit card limit?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      You can invest up to $5,000 if you're using a credit/debit
                      card to invest
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What's the refund process?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      If your investment commitment has been canceled, you will
                      receive a full refund to your original payment method. The
                      details of that refund will vary based on what type of
                      payment you used when first committing to the investment.
                      <br />
                      <br />
                      If you originally paid with a credit and/or debit card,
                      your refund will be automatically initiated. Under normal
                      circumstances, refunds processed via an ACH payment could
                      take up to sixty business days depending on a variety of
                      factors and circumstance. If you've found that passing of
                      time still hasn't yielded results, do not hesitate to
                      reach out for further assistance.
                      <br />
                      <br />
                      For payments made via wire transfer requiring refunds, the
                      necessary paperwork detailing banking information must
                      first be completed and supplied before any monies can be
                      disbursed. In such cases you will need to officially
                      request a wire refund form which should provide the
                      essential information needed for a successful submission.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        How do I calculate my net worth?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      When it comes to building your net worth, you'll need to
                      total your assets (ex equity in your home, cash,
                      retirement, mutual fund, stocks, etc) and subtract all
                      liabilities (debt, car note, student loans etc) associated
                      with them. As Vesterr has outlined, any primary residence
                      and their accompanying mortgages or other loans should not
                      be accounted for when calculating investment limits on the
                      platform.
                      <br />
                      <br />
                      This means that your house's current market value should
                      neither add to nor subtract from the overall tally of your
                      assets. However, if you are investing jointly with a
                      spouse or other partner for the creation of a joint
                      account, then both of your individual income and net worth
                      will be together combined to stipulate the decked out
                      limit for investments.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="7">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What's the process if the deal doesn't go through
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      You will be notified if the deal doesn't go through and
                      you will receive a refund.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="8">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        How can I get in contact with the issuer?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Submit a question in the response section and the
                      issuer/sponsor will respond to you within 24 - 48 hours.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="9">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        Does Vesterr charges fee to invest?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      <a
                        className="generalLink"
                        href={`/${userRoute}/howitworks`}
                        title="How it works"
                      >
                        No. Vesterr is free for investors. Companies pay if they
                        successfully fundraise on the Vesterr platform.
                      </a>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="10">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What's an offering?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Offering — another word for offering securities to the
                      public or the fundraising campaign.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="11">
                    <Accordion.Header>
                      <span className="accordionHeader">What's an issuer?</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Issuer — the company/real estate developer/sponsor
                      fundraising (running an "Offering") on Vesterr.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="12">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What's an investment commitment?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Investment commitment — a pledge to invest in an offering;
                      it is revocable by either you or the company, subject to
                      certain terms and conditions.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="13">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What's a target offering amount?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Target (or Minimum) Offering Amount — the initial
                      fundraising goal of the company, they must reach this
                      amount for the offering to be considered “successful.”
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="14">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        Where do my funds go after I invest?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      When you make an investment the funds will go into a
                      third-party escrow account. Once the funds are
                      successfully raised the funds will be transferred to the
                      issuer.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="15">
                    <Accordion.Header>
                      <span className="accordionHeader">Refunds</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      If your investment commitment has been canceled, you will
                      receive a full refund to your original payment method. The
                      details of that refund will vary based on what type of
                      payment you used when first committing to the investment.
                      <br />
                      <br />
                      If you originally paid with a credit and/or debit card,
                      your refund will be automatically initiated. Under normal
                      circumstances, refunds processed via an ACH payment could
                      take up to sixty business days depending on a variety of
                      factors and circumstance. If you've found that passing of
                      time still hasn't yielded results, do not hesitate to
                      reach out for further assistance.
                      <br />
                      <br />
                      For payments made via wire transfer requiring refunds, the
                      necessary paperwork detailing banking information must
                      first be completed and supplied before any monies can be
                      disbursed. In such cases you will need to officially
                      request a wire refund form which should provide the
                      essential information needed for a successful submission.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="16">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What are the investment limits for a non accredited
                        investor?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Investors with an annual income or net worth below
                      $124,000 are restricted to investing a maximum of $2,500
                      or up to 5 percent of the lesser of their net worth or
                      annual income. Those with an income of at least $124,000
                      have a 10 percent cap on their investment, based on either
                      their net worth or annual income, whichever is lower.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
              {funders && (
                <Accordion>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      <span className="accordionHeader">What's a Funderr?</span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Funderr is a term for every person who issues or proposes
                      to issue any real estate security used on the Vesterr
                      crowdfunding platform.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        Fees issuer has to pay
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      As part of the services that we provide as a crowdfunding
                      portal, Vesterr charges an issuer/funderr onboarding fee
                      of $2,500, and 5% of the capital raised per offering.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What's the max to raise?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Per Regulation CF guidelines up to $5 million per 12
                      months can be raised
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What's the minimum to raise?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      The minimum to raise on Vesterr is $250,000
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        How can I get my project on Vesterr?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Upload your pitch deck to submit your deal{" "}
                      <a
                        className="generalLink"
                        href={`/${userRoute}/howitworks`}
                        title="How it works"
                      >
                        HERE
                      </a>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        What's a Pitch deck?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      A pitch deck is essentially a brief and concise
                      presentation that gives viewers an overview of your
                      business plan, the products or services you offer, as well
                      as the growth traction already experienced or expected.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      <span className="accordionHeader">
                        Will I need a lawyer?
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      Yes, you will need a lawyer who has experience with
                      securities and Regulation Crowdfunding.
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}
            </div>
          </div>
        </section>
        <Footer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  options: state.options,
  allDeals: state.allDeals,
  user: state.user,
});

export default connect(mapStateToProps)(FAQ);
